module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Such dir dein nächstes Quad Abenteuer","short_name":"ofspots","description":"ofspots hilft dir, die besten Quadspots in der Nähe zu finden, erleichtert die Planung und hilft dir, Niemandsland zu finden. Gemeinsam kommen wir weiter!","start_url":"/?utm_source=a2hs","background_color":"#fff","theme_color":"#813405","display":"standalone","icon":"src/content/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6e9e9740eddfb353c05908908a7ad66f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
