module.exports = {
  pathPrefix: `/`,
  url: `https://ofspots.com/`,
  title: `Such dir dein nächstes Quad Abenteuer`,
  titleTemplate: `ofspots - Finde die perfekte Quad-Strecke`,
  description: `ofspots hilft dir, die besten Quadspots in der Nähe zu finden, erleichtert die Planung und hilft dir, Niemandsland zu finden. Gemeinsam kommen wir weiter!`,
  image: `/images/meta-image-default.png`, // Path to the default meta image in `static` folder
  siteLanguage: `de`, // Language tag on <html> element

  // Web App Manifest
  favicon: `src/content/images/favicon.png`, // Used for manifest favicon generation
  shortName: `ofspots`, // shortname for manifest. *Must* be shorter than 12 characters
  themeColor: `#813405`,
  backgroundColor: `#fff`,

  // schema.org JSONLD
  headline: `ofspots`,
  author: `Ducibus LTD`,

  // Google & Twitter
  // googleAnalyticsId: `UA-XXXXX`, // Google Analytics ID
  twitter: ``, // Twitter username
}
